<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="timesheets"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucun rapport
      </template>
      <template v-slot:item.start_date="{ item }">
        {{ item.start_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="toolbar-title">Tableau de bord</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { listTimeSheetsByDate } from "@/services/statistics.api";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";

export default {
  mixins: [infinityScrollMixin],
  data: () => ({
    total: 0,
    options: {},
    editedItem: {},
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "start_date"
      },
      { text: "Nb rapports remplis", value: "completed", align: "center" },
      { text: "Nb rapports manquants", value: "incompleted", align: "center" }
      // { text: "Actions", value: "actions", sortable: false }
    ],
    timesheets: [],
    pageNumber: 1,
    last_page: 0
  }),

  watch: {
    options: {
      handler() {
        this.editedItem.order_by = this.options.sortBy[0];
        this.editedItem.order_by_direction = this.options.sortDesc[0];
        this.timesheets = [];
        this.pageNumber = 1;
        listTimeSheetsByDate(this.pageNumber, this.editedItem).then(
          response => {
            this.timesheets = response.data.data;
            this.last_page = response.data.last_page;
            this.total = response.data.total;
          }
        );
      },
      deep: true
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true) {
        this.pageNumber += 1;
        listTimeSheetsByDate(this.pageNumber, this.editedItem).then(
          response => {
            for (const i in response.data.data) {
              this.timesheets.push(response.data.data[i]);
            }
          }
        );
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    getAllTimeSheetsByDate() {
      listTimeSheetsByDate(this.pageNumber, this.editedItem).then(response => {
        this.timesheets = response.data.data;
        this.last_page = response.data.last_page;
      });
    },
    initialize() {
      this.getAllTimeSheetsByDate();
    }
  }
};
</script>
